@import "https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap";
body {
  margin: 20px 0;
  padding: 0;
}

#root {
  max-height: 100vh;
  padding: 0 40px;
  font-family: Share Tech Mono, monospace;
  font-size: 14px;
  overflow: hidden;
}

#root header {
  width: calc(100vw - 80px);
  height: 240px;
}

#root header h1 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

#root header h2 {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

#root header section {
  margin: 0 -15px;
}

#root header section span {
  position: relative;
}

#root header section span:after {
  color: #e91e63;
  content: attr(data-chord);
  z-index: 1000;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  bottom: -2px;
  left: 34px;
}

#root header section span img {
  height: 120px;
  display: inline-block;
}

#root main {
  flex-flow: column wrap;
  align-content: space-between;
  height: calc(100vh - 260px);
  display: flex;
}

#root > main > section {
  border-top: 1px solid #f5f5f5;
  padding: 10px 0;
  position: relative;
}

#root > main > section:first-child {
  border-top: none;
}

#root > main > section p {
  margin: 0;
  padding: 10px 0;
  position: relative;
}

#root > main > section p span {
  color: #e91e63;
  font-weight: bold;
  position: absolute;
  top: -5px;
}
/*# sourceMappingURL=index.3168fe22.css.map */
