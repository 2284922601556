@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

body {
    margin: 20px 0;
    padding: 0;
}

#root {
    font-family: 'Share Tech Mono', monospace;
    font-size: 14px;
    padding: 0 40px;
    max-height: 100vh;
    overflow: hidden;

    header {
        width: calc(100vw - 80px);
        height: 240px;

        h1 {
            margin: 0;
            padding: 0;
            font-size: 24px;
        }

        h2 {
            margin: 0;
            padding: 0;
            font-size: 18px;
        }

        section {
            margin: 0 -15px;
            span {
                position: relative;
                &::after {
                    position: absolute;
                    bottom: -2px;
                    left: 34px;
                    color: #E91E63;
                    font-weight: bold;
                    font-size: 18px;
                    content: attr(data-chord);
                    z-index: 1000;
                    font-size: 12px;
                }
                img {
                    display: inline-block;
                    height: 120px;
                }
            }
        }
    }

    main {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: space-between;
        height: calc(100vh - 220px - 40px);
    }
}



#root > main > section {
    &:first-child {
        border-top: none;
    }
    border-top: solid 1px #F5F5F5;
    padding: 10px 0;
    position: relative;

    p {
        padding: 10px 0;
        margin: 0;
        position: relative;
    
        span {
            position: absolute;
            top: -5px;
            font-weight: bold;
            color: #E91E63;
        }

    }
}